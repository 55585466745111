import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0];

export const dictionary = {
		"/(bootstrap)": [6,[2]],
		"/(bootstrap)/admin": [7,[2]],
		"/(bootstrap)/admin/editCam": [8,[2]],
		"/(bootstrap)/admin/editPieces": [9,[2]],
		"/(bootstrap)/admin/register": [10,[2]],
		"/(bootstrap)/admin/reset": [11,[2]],
		"/(bootstrap)/admin/updateAffaires": [12,[2]],
		"/(bootstrap)/affaires": [13,[2]],
		"/(tailwind)/affaire": [22,[3]],
		"/(bootstrap)/agents": [~14,[2]],
		"/(bootstrap)/calendar": [~15,[2]],
		"/(bootstrap)/dashboardmanager": [16,[2]],
		"/(bootstrap)/history": [~17,[2]],
		"/(tailwind)/home": [23,[3,4]],
		"/(tailwind)/home/logout": [~24,[3,4]],
		"/(tailwind)/login": [25,[3]],
		"/(bootstrap)/manageAgents": [~18,[2]],
		"/(bootstrap)/manageAgents/new": [~19,[2]],
		"/(bootstrap)/newcalendar": [20,[2]],
		"/(bootstrap)/sites": [21,[2]],
		"/(tailwind)/tests": [26,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';